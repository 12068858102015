<script setup>
import { getCurrentInstance, ref } from "vue";
import { setSelectedApp } from "@/composables/useSalesChannelManagementApp";
import { useRouter } from "vue-router/composables";

const store = getCurrentInstance().proxy.$store;
const apps = ref(store.getters.apps);
const router = useRouter();

async function onAppClick(app) {
  if (store.getters.isDev) {
    await store.dispatch("resetSalesChannelManagementAppStore");
  }

  if (process.env.VUE_APP_SCMA_ENVIRONMENT === "local" || store.getters.isDev) {
    await setSelectedApp(app.name);
  }

  await router.push({
    name: "salesChannelManagementApp",
    params: { name: app.name }
  });
}
</script>

<template>
  <div class="d-flex flex-wrap">
    <div
      v-for="app in apps"
      :key="app.name"
      class="card card-custom cursor-pointer integrations-card ma-5"
      @click="onAppClick(app)"
    >
      <div class="card-body d-flex align-items-center">
        <img :alt="app.name" :src="app.logo" class="app-logo" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.integrations-card {
  min-width: 30%;
  min-height: 300px;
}

.app-logo {
  max-height: 70px;
  margin: auto;
}
</style>
